import React from 'react';
import user1 from '../../../assets/img/thibault-rouet.jpg';
import user2 from '../../../assets/img/remy-finet.jpg';
import user3 from '../../../assets/img/christopher-barrat.jpg';
import styled from 'styled-components';

type AdvisorTabProps = {
  setShowAdvisorTab: React.Dispatch<React.SetStateAction<boolean>>;
  setShowZendeskForm: React.Dispatch<React.SetStateAction<boolean>>;
  setShowCallbackForm: React.Dispatch<React.SetStateAction<boolean>>;
};

export function AdvisorTab({ setShowZendeskForm, setShowCallbackForm, setShowAdvisorTab }: AdvisorTabProps) {
  return (
    <AdvisorTabContainer>
      <TeamImages>
        {[user1, user2, user3].map((userImage, index) => (
          <ImageContainer key={index}>
            <img src={userImage} alt={`Conseiller ${index + 1}`} />
          </ImageContainer>
        ))}
      </TeamImages>
      <AdvisorTitle>Notre équipe vous répond dans les plus brefs délais !</AdvisorTitle>
      <AdvisorParagraph>Vous pouvez demander à être rappelé par un conseiller ou nous soumettre directement votre demande via un formulaire.</AdvisorParagraph>
      <AdvisorButtons>
        <YellowButton
          onClick={() => {
            setShowAdvisorTab(false);
            setShowCallbackForm(true);
          }}
        >
          Être rappelé par un conseiller
        </YellowButton>
        <YellowButton
          isForm
          onClick={() => {
            setShowAdvisorTab(false);
            setShowZendeskForm(true);
          }}
        >
          Envoyer votre demande
        </YellowButton>
      </AdvisorButtons>
    </AdvisorTabContainer>
  );
}

const AdvisorTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 16px;
  padding: 16px;
`;

const AdvisorTitle = styled.h2`
  font-size: 16px;
  text-align: center;
  margin: 0;
  font-family: 'StyreneA', sans-serif !important;
  line-height: 24px !important;
  width: 70%;
`;

const AdvisorParagraph = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  font-family: 'StyreneA', sans-serif !important;
  font-weight: 100 !important;
  line-height: 21px !important;
  color: #676767;
  margin-bottom: 10px;
`;

const AdvisorButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const YellowButton = styled.button<{ isUser: boolean }>`
  background-color: #ffc846;
  color: black;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-family: 'StyreneA', sans-serif !important;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  width: ${props => (props.isForm ? '70%' : '80%')};
`;

const TeamImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e5e7eb;
  border: 2px solid white;
  margin-left: -15px;
  &:first-child {
    margin-left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
