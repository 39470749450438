import React, { RefObject } from 'react';
import aiIcon from '../../../assets/img/ExaTest.svg';
import { MessageContent } from './MessageContent';
import { ChatMessage } from '../../utils/chatbot/types';
import styled, { keyframes } from 'styled-components';

type ChatMessagesProps = {
  chatHistory: ChatMessage[];
  isLoading: boolean;
  isTyping: boolean;
  currentTypingMessage: string;
  lastMessageRef: RefObject<HTMLDivElement>;
  onRequestAssistance?: () => void;
};

export function ChatMessages({ chatHistory, isLoading, isTyping, currentTypingMessage, lastMessageRef, onRequestAssistance }: ChatMessagesProps) {
  return (
    <ChatList>
      {chatHistory.map((message, index) => {
        const isUser = message[0] === 'client';
        const isLastMessage = index === chatHistory.length - 1;
        const textContent = message[1];
        const messageLength = textContent.length;
        return (
          <ChatItem key={index} isUser={isUser}>
            {!isUser && <Avatar src={aiIcon} alt="ExaBot" isUser={isUser} />}
            <ChatBubble isUser={isUser} ref={isLastMessage ? lastMessageRef : null} messageLength={messageLength}>
              <MessageContent content={message[1]} onRequestAssistance={!isUser ? onRequestAssistance : undefined} />
            </ChatBubble>
          </ChatItem>
        );
      })}
      {isLoading && (
        <ChatItem isUser={false}>
          <Avatar src={aiIcon} alt="ExaBot" isUser={false} />
          <ChatBubble isUser={false} messageLength={50}>
            <LoadingDots>
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </LoadingDots>
          </ChatBubble>
        </ChatItem>
      )}
      {isTyping && currentTypingMessage && (
        <ChatItem isUser={false}>
          <Avatar src={aiIcon} alt="ExaBot" isUser={false} />
          <ChatBubble isUser={false} ref={lastMessageRef} messageLength={currentTypingMessage.length}>
            <MessageContent content={currentTypingMessage} onRequestAssistance={onRequestAssistance} />
          </ChatBubble>
        </ChatItem>
      )}
    </ChatList>
  );
}
const ChatList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #ebebeb;
  width: 100%;
  flex-grow: 1;
`;

const ChatItem = styled.li<{ isUser: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isUser ? 'row-reverse' : 'row')};
  align-items: flex-start;
  margin-bottom: 16px;
`;

const ChatBubble = styled.div<{ isUser: boolean; messageLength: number }>`
  background-color: ${props => (props.isUser ? '#337C99' : '#ffffff')};
  color: ${props => (props.isUser ? '#ffffff' : '#000000')};
  border-radius: 16px;
  line-height: 1.2rem !important;
  padding: 12px;
  max-width: ${props => (props.messageLength < 120 ? '70%' : '100%')};
  word-wrap: break-word !important;
  align-self: flex-start;
`;

const Avatar = styled.img<{ isUser: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: ${props => (props.isUser ? '0 0 0 10px' : '0 10px 0 0')};
`;

const LoadingDots = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 1.5em;
`;

const Dot = styled.span`
  font-size: 1.5em;
  color: #000;
  animation: ${keyframes`
    0% {
      opacity: 0.2;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-5px);
    }
    100% {
      opacity: 0.2;
      transform: translateY(0);
    }
  `} 1.5s infinite;
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;
