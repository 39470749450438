import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../common/constants';

import fb from '../../../assets/icons/footer-facebook.svg';
import insta from '../../../assets/icons/footer-insta.svg';
import youtube from '../../../assets/icons/footer-youtube.svg';

const SOCIAL_NETWORK_LIST = [
  {
    img: fb,
    url: 'shared.front.links.facebookExalink',
    onlyFR: false
  },
  {
    img: insta,
    url: 'shared.front.links.instagramExalink',
    onlyFR: false
  },
  {
    img: youtube,
    url: 'shared.front.links.youtubeExalink',
    onlyFR: false
  }
];

const SocialNetworkList: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { t } = useTranslation('sharedComponents');
  const locale = process.env.GATSBY_LOCALE || 'fr';

  if (isMobile) {
    const socialNetworkMobileList = SOCIAL_NETWORK_LIST;
    return (
      <>
        <StyledSocialIconListWrapper isMobile>
          {socialNetworkMobileList.map(({ img, url }, index) => (
            <a href={t(url)} target="_blank" key={`asn${index + 1}`}>
              <StyledSocialIconWrapper isMobile={isMobile} src={img} key={`sn${index + 1}`} alt={`icon social network ${index + 1}`} />
            </a>
          ))}
        </StyledSocialIconListWrapper>
      </>
    );
  }

  const socialNetworkList = locale === 'fr' ? SOCIAL_NETWORK_LIST : SOCIAL_NETWORK_LIST.filter(({ onlyFR }) => !onlyFR);
  return (
    <StyledSocialIconListWrapper isMobile={false}>
      {socialNetworkList.map(({ img, url }, index) => (
        <a href={t(url)} target="_blank" key={`asn${index + 1}`}>
          <StyledSocialIconWrapper isMobile={isMobile} src={img} key={`sn${index + 1}`} alt={`icon social network ${index + 1}`} />
        </a>
      ))}
    </StyledSocialIconListWrapper>
  );
};

export const NewFooterSocialNetworkExalink: React.FC = () => {
  const { t } = useTranslation('sharedComponents');
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  return (
    <StyledSocialNetworkAndPaymentContainer isMobile={isMobile}>
      <StyledSocialTextAndIconWrapper>
        <span>{t('shared.footer.socialNetwork')}</span>
        <SocialNetworkList isMobile={isMobile} />
      </StyledSocialTextAndIconWrapper>
      <StyledSocialAndPaymentWrapper isMobile={isMobile}>
        <StyledLegalMention>
          <span>Exaprint est une marque de Exagroup SAS, a Cimpress Company. SAS au capital de 2.000.000 €, Siret n°38035323500068, RCS Montpellier</span>
        </StyledLegalMention>
      </StyledSocialAndPaymentWrapper>
    </StyledSocialNetworkAndPaymentContainer>
  );
};

const StyledSocialNetworkAndPaymentContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? 1.5 : 2)}rem;
`;

const StyledSocialAndPaymentWrapper = styled.div<{ isMobile: boolean }>`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    `
    align-items: center;
    flex-direction: column-reverse;
    gap: 1.5rem;
  `}
`;

const StyledSocialTextAndIconWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  > span {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const StyledSocialIconListWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 0.625rem;
`;

const StyledSocialIconWrapper = styled.img<{ isMobile: boolean }>`
  cursor: pointer;
  height: 60px;
  transition: border-color 0.2s ease-in;
  width: 60px;

  &:hover {
    border-radius: 30px;
    border: 1px solid #ebebeb;
  }
  ${({ isMobile }) =>
    isMobile &&
    `
      height: 40px;
      width: 40px;
  `}
`;

const StyledLegalMention = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  > span {
    color: #989898;
    font-family: 'StyreneA', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;
