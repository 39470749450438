import React, { useState } from 'react';
import styled from 'styled-components';

interface FeedbackCommentProps {
  onSubmit: (comment: string) => void;
  onClose: () => void;
}

export function FeedbackComment({ onSubmit, onClose }: FeedbackCommentProps) {
  const [comment, setComment] = useState('');

  const handleSubmit = () => {
    onSubmit(comment.trim());
    onClose();
  };

  return (
    <CommentContainer>
      <CommentText>N'hésitez pas à nous dire en quelques mots ce qui vous a plu ou ce que nous pourrions améliorer.</CommentText>
      <CommentTextarea
        placeholder="Laisser un commentaire (facultatif)"
        value={comment}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setComment(e.target.value)}
      />
      <ButtonContainer>
        <CloseButton onClick={onClose}>Fermer</CloseButton>
        <SubmitButton onClick={handleSubmit}>Envoyer</SubmitButton>
      </ButtonContainer>
    </CommentContainer>
  );
}

const CommentContainer = styled.div`
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 16px;
`;

const CommentText = styled.p`
  font-size: 12px;
  color: #676767;
  margin-bottom: 12px;
  font-family: 'StyreneA', sans-serif !important;
  text-align: center;
  line-height: 1.5;
`;

const CommentTextarea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  margin-bottom: 12px;
  resize: vertical;
  min-height: 80px;
  font-family: 'StyreneA', sans-serif !important;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const Button = styled.button`
  padding: 6px 12px;
  border-radius: 4px;
  font-family: 'StyreneA', sans-serif !important;
  cursor: pointer;
`;

const CloseButton = styled(Button)`
  background: none;
  border: 1px solid #e5e7eb;
  color: #676767;
`;

const SubmitButton = styled(Button)`
  background-color: #ffc846;
  border: none;
  color: black;
`;
