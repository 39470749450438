import React from 'react';
import styled from 'styled-components';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { MOBILE_SIZE } from '../../common/constants';

import { NewFooterContactUs } from './NewFooterContactUs';
import { NewFooterNewsletter } from './NewFooterNewsletter';
import { NewFooterSocialNetworkAndPayment } from './NewFooterSocialNetworkAndPayment';
import { NewFooterSocialNetworkExalink } from './NewFooterSocialNetworkExalink';
import { NewFooterLink } from './NewFooterLink';
import { NewFooterExalinkLink } from './NewFooterExalinkLink';
import { FooterBlocksCollection } from '../../generated/contentful-generated-types';

interface FooterProps {
  blocks?: FooterBlocksCollection;
}

export const NewFooterComponent: React.FC<FooterProps> = ({ blocks }) => {
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);
  const isWindow = typeof window !== 'undefined';
  const isExalinkPage = isWindow && window.location.hostname.includes('exalink');

  return (
    <>
      {isExalinkPage && (
        <StyledFooterContainer isMobile={isMobile}>
          <div>
            <NewFooterExalinkLink />
            <StyledSeparatorLine />
            <NewFooterSocialNetworkExalink />
          </div>
        </StyledFooterContainer>
      )}
      {!isExalinkPage && (
        <StyledFooterContainer isMobile={isMobile}>
          <div>
            <NewFooterContactUs />
            <StyledSeparatorLine />
            <NewFooterNewsletter />
            <StyledSeparatorLine />
            <NewFooterLink blocks={blocks} />
            <StyledSeparatorLine />
            <NewFooterSocialNetworkAndPayment />
          </div>
        </StyledFooterContainer>
      )}
    </>
  );
};

const StyledFooterContainer = styled.div<{ isMobile: boolean }>`
  align-items: center;
  background: #323232;
  display: flex;
  justify-content: center;
  padding: ${({ isMobile }) => (isMobile ? '1.5rem' : '2rem 3.5rem')};
  > div {
    display: flex;
    flex-direction: column;
    gap: ${({ isMobile }) => (isMobile ? 1.5 : 2)}rem;
    max-width: 1400px;
  }
`;

const StyledSeparatorLine = styled.div`
  background: #676767;
  height: 1px;
`;
