import { useState, useEffect, useRef, FormEvent } from 'react';
import axios from 'axios';
import { getWithExpiry, setWithExpiry } from '../utils/chatbot/localStorageWithExpiry';
import { ChatMessage, ChatMessageResponse, FormState } from '../utils/chatbot/types';
import { isValidEmail } from '../utils/chatbot/validation';
import { gtmPushEvent } from '../common/gtm';

export function useChatLogic(apiUrl: string) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [input, setInput] = useState<string>('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>(() => {
    if (typeof window !== 'undefined') {
      const savedChatHistory = getWithExpiry('chatHistory');
      if (!savedChatHistory || typeof savedChatHistory !== 'string') return [];
      try {
        return JSON.parse(savedChatHistory);
      } catch {
        return [];
      }
    }
    return [];
  });

  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [currentTypingMessage, setCurrentTypingMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showZendeskForm, setShowZendeskForm] = useState<boolean>(false);
  const [showAdvisorTab, setShowAdvisorTab] = useState<boolean>(false);
  const [showCallbackForm, setShowCallbackForm] = useState<boolean>(false);
  const [showGdprMessage, setShowGdprMessage] = useState<boolean>(true);
  const [ticketId, setTicketId] = useState<null | number>(() => {
    if (typeof window !== 'undefined') {
      const savedTicketId = getWithExpiry('ticketId');
      return savedTicketId ? JSON.parse(savedTicketId) : 0;
    }
    return 0;
  });
  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false);
  const [hasFilledInitialForm, setHasFilledInitialForm] = useState<boolean>(() => {
    const savedValue = getWithExpiry('hasFilledInitialForm');
    return savedValue ? JSON.parse(savedValue) : false;
  });

  const [hasSentFeedback, setHasSentFeedback] = useState<boolean>(() => {
    if (typeof window !== 'undefined') {
      const savedHasSentFeedback = getWithExpiry('hasSentFeedback');
      return savedHasSentFeedback ? JSON.parse(savedHasSentFeedback) : false;
    }
    return false;
  });
  const [showFeedback, setShowFeedback] = useState<boolean>(false);

  const lastMessageRef = useRef<HTMLDivElement>(null);

  const [formState, setFormState] = useState<FormState>(() => {
    const savedFormState = getWithExpiry('formState');
    return savedFormState
      ? JSON.parse(savedFormState)
      : {
          userName: '',
          email: '',
          phoneNumber: '',
          reason: '',
          subject: '',
          description: '',
          requestType: '',
          secondRequestType: '',
          requestTypeID: 0,
          secondRequestTypeID: 0,
          quote_number: undefined,
          delivery_points_number: undefined,
          order_number: undefined,
          account_number: '',
          attachment_token: '',
          formType: 'ticket'
        };
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokenImages, setTokenImages] = useState<string[]>([]);

  useEffect(() => {
    const ttl = 30 * 60 * 1000; // 30 minutes
    setWithExpiry('chatHistory', chatHistory, ttl);
    setWithExpiry('formState', formState, ttl);
    setWithExpiry('hasFilledInitialForm', hasFilledInitialForm, ttl);
    setWithExpiry('ticketId', ticketId, ttl);
    setWithExpiry('hasSentFeedback', hasSentFeedback, ttl);
  }, [chatHistory, formState, hasFilledInitialForm, ticketId, hasSentFeedback]);

  useEffect(() => {
    if (isOpen && chatHistory.length === 0 && !hasSentInitialMessage) {
      setChatHistory([
        [
          'exabot',
          'Bonjour ! Je suis Exabot, votre assistant virtuel. Je suis un chatbot en version bêta. Cela signifie que je continue à apprendre et à m’améliorer grâce à vos interactions. Comment puis-je vous aider aujourd’hui ?'
        ]
      ]);
      setHasSentInitialMessage(true);
    } else if (!isOpen) {
      setHasSentInitialMessage(false);
    }
  }, [isOpen, chatHistory, hasSentInitialMessage]);

  useEffect(() => {
    if (!isOpen) return;
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [isOpen, chatHistory, currentTypingMessage, isTyping, showAdvisorTab, showZendeskForm, showCallbackForm]);

  useEffect(() => {
    if (isOpen) {
      gtmPushEvent({
        event: 'openchatbot',
        pageUrl: window.location.href
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (showAdvisorTab) {
      gtmPushEvent({
        event: 'nouscontacter',
        pageUrl: window.location.href
      });
    }
  }, [showAdvisorTab]);

  useEffect(() => {
    if (showCallbackForm) {
      gtmPushEvent({
        event: 'etrerappele',
        pageUrl: window.location.href
      });
    }
  }, [showCallbackForm]);

  useEffect(() => {
    if (showZendeskForm) {
      gtmPushEvent({
        event: 'envoyerdemande',
        pageUrl: window.location.href
      });
    }
  }, [showZendeskForm]);

  useEffect(() => {
    if (hasFilledInitialForm) {
      gtmPushEvent({
        event: 'demarrerchat',
        pageUrl: window.location.href
      });
    }
  }, [hasFilledInitialForm]);

  const callExternalApi = async (inputValue: string): Promise<ChatMessageResponse | undefined> => {
    try {
      setIsLoading(true);
      const response = await axios.post(apiUrl, {
        input: {
          ticket_id: ticketId,
          question: inputValue,
          chat_history: chatHistory,
          name: formState.userName,
          email: isValidEmail(formState.email) ? formState.email : 'user@example.com'
        }
      });
      if (response?.data?.output?.ticket_id) {
        setTicketId(response.data.output.ticket_id);
      }
      setIsLoading(false);
      return response.data.output as ChatMessageResponse;
    } catch (error) {
      setIsLoading(false);
      console.error("Erreur lors de l'appel à l'API:", error);
      return undefined;
    }
  };

  const typeMessage = async (message: string) => {
    setIsTyping(true);
    const chunkSize = 5;
    for (let i = 0; i <= message.length; i += chunkSize) {
      setCurrentTypingMessage(message.slice(0, i));
      await new Promise(resolve => setTimeout(resolve, 10));
    }
    setIsTyping(false);
    setChatHistory(prev => [...prev, ['exabot', message]]);
    setCurrentTypingMessage('');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;
    setChatHistory(prev => [...prev, ['client', input]]);
    setInput('');
    const aiResponse = await callExternalApi(input);
    if (aiResponse) {
      await typeMessage(aiResponse.answer);
    }
  };

  const handleBack = () => {
    if (showAdvisorTab) setShowAdvisorTab(false);
    if (showZendeskForm) setShowZendeskForm(false);
    if (showCallbackForm) setShowCallbackForm(false);
  };

  return {
    isOpen,
    setIsOpen,
    input,
    setInput,
    chatHistory,
    setChatHistory,
    isTyping,
    setIsTyping,
    currentTypingMessage,
    setCurrentTypingMessage,
    isLoading,
    setIsLoading,
    showZendeskForm,
    setShowZendeskForm,
    showCallbackForm,
    setShowCallbackForm,
    showAdvisorTab,
    setShowAdvisorTab,
    showGdprMessage,
    setShowGdprMessage,
    hasFilledInitialForm,
    setHasFilledInitialForm,
    formState,
    setFormState,
    errors,
    setErrors,
    isSubmitting,
    setIsSubmitting,
    tokenImages,
    setTokenImages,
    handleBack,
    ticketId,
    hasSentFeedback,
    setHasSentFeedback,
    showFeedback,
    setShowFeedback,
    handleSubmit,
    callExternalApi,
    lastMessageRef
  };
}
