import React from 'react';
import styled from 'styled-components';
import { ThumbsUp, ThumbsDown } from 'lucide-react';

interface FeedbackFormProps {
  onRating: (rating: number) => void;
}

export function FeedbackForm({ onRating }: FeedbackFormProps) {
  return (
    <FeedbackWrapper>
      <FeedbackContainer>
        <FeedbackText>Cette conversation vous a-t-elle été utile ?</FeedbackText>
        <ThumbContainer>
          <ThumbButton onClick={() => onRating(4)} aria-label="Excellent (4/4)">
            <ThumbsUp size={20} />
          </ThumbButton>
          <ThumbButton onClick={() => onRating(1)} aria-label="Mauvais (1/4)">
            <ThumbsDown size={20} />
          </ThumbButton>
        </ThumbContainer>
      </FeedbackContainer>
    </FeedbackWrapper>
  );
}

const FeedbackWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
`;

const FeedbackContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  position: relative;
`;

const FeedbackText = styled.p`
  font-size: 15px;
  margin: 0;
  color: #947024;
  line-height: 1.5;
  font-weight: 500;
  font-family: 'StyreneA', sans-serif !important;
`;

const ThumbContainer = styled.div`
  display: flex;
  gap: 12px;
`;

const ThumbButton = styled.button`
  background: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 8px;
  color: #337c99;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    color: white;
    background: #337c99;
    transform: scale(1.05);
  }
`;
