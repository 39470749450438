import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { HeaderProps } from './Header';

interface Props extends HeaderProps {
  isMobile: boolean;
}

const exalinkLogo = require('../../assets/img/logo_exalink.svg');

export const HeaderExalink: FunctionComponent<Props> = ({ isMobile }) => {
  return (
    <div key="exalinkDiv">
      <StyledHeaderExalinkContent>
        {isMobile || (
          <StyledHeaderExalinkBottom>
            <StyledHeaderExalinkCenter>
              <LogoExalinkContainer>
                <img src={exalinkLogo} alt="Logo Exalink" />
              </LogoExalinkContainer>
            </StyledHeaderExalinkCenter>
          </StyledHeaderExalinkBottom>
        )}
        {!isMobile || (
          <StyledHeaderExalinkBottom>
            <StyledHeaderExalinkCenter>
              <LogoExalinkMobileContainer>
                <img src={exalinkLogo} alt="Logo Exalink" />
              </LogoExalinkMobileContainer>
            </StyledHeaderExalinkCenter>
          </StyledHeaderExalinkBottom>
        )}
      </StyledHeaderExalinkContent>
    </div>
  );
};

const LogoExalinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  & img {
    width: 40%;
    max-width: 1600px;
    height: auto;
  }
`;

const LogoExalinkMobileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;

  & img {
    width: 100%;
    max-width: 1600px;
    height: auto;
  }
`;

const StyledHeaderExalinkContent = styled.div`
  position: relative;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
`;

const StyledHeaderExalinkBottom = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  padding: 10px 0;
`;

const StyledHeaderExalinkCenter = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
`;
