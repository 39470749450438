import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
const devIcon = require('../../assets/icons/settings.svg');
import { createHttpLink, InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';

import { useQraphql } from '../hooks/useGraphql';
import { ApolloClient } from '@apollo/client';

const isDevMode = process.env.NODE_ENV === 'development';
const isPreviewServer = isDevMode && !process.env.GATSBY_LOCAL_DEV;
const branch = process.env.GATSBY_ENVIRONMENT === 'production' ? 'production' : 'master';
const envName = process.env.GATSBY_ENVIRONMENT === 'production' ? 'Production' : 'Stage';
const subDomain = process.env.GATSBY_ENVIRONMENT === 'production' ? 'www' : 'stage';

export const RefreshBanner: FunctionComponent = ({ children }) => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  return (
    <>
      <Banner>
        <ShowBannerIcon
          src={devIcon}
          onClick={() => {
            setShowBanner(!showBanner);
          }}
        />
      </Banner>
      {showBanner && <BannerContent closeBanner={() => setShowBanner(false)} />}
      {children}
    </>
  );
};

const GITLAB_BUILD_TOKEN = process.env.GATSBY_GITLAB_BUILD_TOKEN || 'missing-gitlab-build-token'; //Project's level trigger token
const GITLAB_API_TOKEN = process.env.GATSBY_GITLAB_API_TOKEN || 'missing-gitlab-api-token'; // Either user's provate token or project level token. Strangely, must have "api" rights (instead of "read-api")

const client = new ApolloClient({
  link: createHttpLink({ uri: 'https://gitlab.com/api/graphql', headers: { 'PRIVATE-TOKEN': GITLAB_API_TOKEN } }),
  cache: new InMemoryCache()
});

interface Props {
  closeBanner: () => void;
}

const BannerContent: FunctionComponent<Props> = ({ closeBanner }) => {
  const { loading, data } = useQraphql(
    gql`
      query RunningPipelines($fullPath: ID!, $branch: String!) {
        project(fullPath: $fullPath) {
          pipelines(status: RUNNING, ref: $branch) {
            nodes {
              startedAt
              detailedStatus {
                detailsPath
              }
            }
          }
        }
      }
    `,
    { client, variables: { fullPath: 'exaprint/phoenix/phoenix-front', branch } }
  );
  const [buildError, setBuildError] = useState<string | null>();
  const nodes = !loading && data ? data.project.pipelines.nodes : [];
  const startedAt = nodes.length > 0 ? nodes[0].startedAt : null;
  const buildPath = nodes.length > 0 ? nodes[0].detailedStatus.detailsPath : null;

  const fetchNewPipeline = async (locale: string): Promise<boolean> => {
    let buildErrorMessage;

    const body = new URLSearchParams();
    body.append('token', GITLAB_BUILD_TOKEN);
    body.append('ref', branch);
    body.append('variables[LOCALE]', locale);

    try {
      const response = await fetch('https://gitlab.com/api/v4/projects/9510369/trigger/pipeline', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: body.toString()
      });

      if (response.status === 201) {
        window.location.reload();
      } else {
        buildErrorMessage = `${response.statusText} (${response.status})`;
      }
    } catch (error) {
      buildErrorMessage = (error as any).message;
    }

    setBuildError(buildErrorMessage);
    return buildErrorMessage === undefined;
  };

  return (
    <Container
      onClick={(event: any) => {
        if (event.target === event.currentTarget) closeBanner();
      }}
    >
      <BannerButton
        onClick={async () => {
          await fetch('/__refresh', { method: 'POST' });
          window.location.reload();
        }}
      >
        <ButtonTitle>Reload data from Contentful</ButtonTitle>
        <div>This will reload the page data from Contentful.</div> <div>Will not work with generated data (ex: menu content). </div>
        <div>
          After clicking, <u>this can take up to 30 seconds to be effective</u>.
        </div>
      </BannerButton>

      <BannerButton onClick={() => fetch('/restart', { method: 'POST' })} disabled={!isPreviewServer}>
        <ButtonTitle>Restart preview server</ButtonTitle>
        <div>Use when previous option was not enough. This will also reload the latest application code. Restart can take up to 5 minutes.</div>
      </BannerButton>

      <BannerButton onClick={async () => await fetchNewPipeline('fr')} disabled={loading || !!startedAt}>
        <ButtonTitle>Launch {envName} build for FR</ButtonTitle>
        <div>
          {' '}
          This will start a {envName} build pipeline in Gitlab for {subDomain}.exaprint.fr
        </div>
      </BannerButton>
      <BannerButton onClick={async () => await fetchNewPipeline('es')} disabled={loading || !!startedAt}>
        <ButtonTitle>Launch {envName} build for ES</ButtonTitle>
        <div>
          {' '}
          This will start a {envName} build pipeline in Gitlab for {subDomain}.exaprint.es
        </div>
      </BannerButton>

      {startedAt && (
        <BuildMessage target="_blank" href={`https://gitlab.com/${buildPath}`}>
          The site is already being built since: {new Date(startedAt).toLocaleString('fr')}
        </BuildMessage>
      )}
      {buildError && <ErrorMessage>{buildError}</ErrorMessage>}
    </Container>
  );
};

const ShowBannerIcon = styled.img`
  width: 30px;
  height: 30px;
  margin: 5px;
  cursor: pointer;
`;

const Banner = styled.div`
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 9999;
`;

const ButtonTitle = styled.div`
  margin: 5px;
  font-weight: bold;
  font-size: 18px;
`;

const BannerButton = styled.button`
  padding: 20px;
  display: block;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const ErrorMessage = styled.div`
  color: #8b0000;
  font-weight: 700;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const BuildMessage = styled.a`
  color: #fff;
  text-decoration: underline !important;
  display: block;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  &:hover {
    color: #fff;
    font-weight: 700;
  }
`;

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 30000;
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #981540;
  color: #fff;
  padding: 10px;
  backdrop-filter: blur(6px);
`;
