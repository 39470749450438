import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const StyledMarkdown = styled.div`
  word-break: break-word !important;
  vertical-align: unset !important;
  font-weight: normal !important;
  font-size: 14px !important;

  a {
    color: #3b82f6 !important;
    text-decoration: underline !important;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 1.5rem;
    list-style-type: disc;
  }
  li {
    margin: 0.5em 0;
  }
`;

const LinkRenderer = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

const AssistanceButton = styled.button`
  background-color: #4caed1;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  margin-top: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #2f89aa;
  }
`;

interface MessageContentProps {
  content: string;
  onRequestAssistance?: () => void;
}

export const MessageContent = ({ content, onRequestAssistance }: MessageContentProps) => {
  const lowerContent = content.toLowerCase();

  const containsAssistanceKeywords = lowerContent.includes("joindre l'assistance");

  const showButton = onRequestAssistance && containsAssistanceKeywords;

  return (
    <StyledMarkdown>
      <ReactMarkdown
        source={content}
        renderers={{
          link: LinkRenderer
        }}
      />
      {showButton && (
        <div>
          <AssistanceButton onClick={onRequestAssistance}>Joindre l'assistance</AssistanceButton>
        </div>
      )}
    </StyledMarkdown>
  );
};
