import React from 'react';
import styled from 'styled-components';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import { FooterBlocksCollection } from '../../generated/contentful-generated-types';
import { MOBILE_SIZE } from '../../common/constants';

import logo from '../../../assets/img/logo_exalink_footer.svg';

interface linksCollection {
  linksCollection: {
    items: {
      title: string;
      url: string;
    }[];
  };
}

const ItemsLinkList = (linksCollection: linksCollection) => {
  return (
    <ul>
      {linksCollection?.linksCollection?.items.map(link => {
        return (
          <li key={`footerlink-${link.title}`}>
            <a href={`${link.url}`}>{link.title}</a>
          </li>
        );
      })}
    </ul>
  );
};

const ItemsMobileLinkList = (linksCollection: linksCollection) => {
  return (
    <ul>
      {linksCollection?.linksCollection?.items.map(link => {
        return (
          <li key={`footerlink-${link.title}`}>
            <a href={`${link.url}`}>{link.title}</a>
          </li>
        );
      })}
    </ul>
  );
};

export const NewFooterExalinkLink: React.FC<{ blocks?: FooterBlocksCollection }> = () => {
  const isMobile = useIsWindowWidthBelow(MOBILE_SIZE);

  const blocks = {
    items: [
      {
        title: 'Colonne 1',
        linksCollection: {
          items: [
            {
              title: 'Accueil',
              url: 'https://www.exalink.fr/'
            },
            {
              title: 'Nous contacter',
              url: 'https://www.exalink.fr/contact'
            },
            {
              title: 'FAQ',
              url: 'https://exalink.zendesk.com'
            }
          ]
        }
      },
      {
        title: 'Colonne 2',
        linksCollection: {
          items: [
            {
              title: 'Mentions légales',
              url: 'https://www.exalink.fr/mentions-legales'
            },
            {
              title: 'Politique de confidentialité',
              url: 'https://www.exalink.fr/politique-de-confidentialite'
            },
            {
              title: "Politique d'utilisation des cookies",
              url: 'https://www.exalink.fr/politique-utilisation-cookies'
            }
          ]
        }
      },
      {
        title: 'Colonne 3',
        linksCollection: {
          items: [
            {
              title: 'CGU & CGV',
              url: 'https://www.exalink.fr/cgu-cgv'
            },
            {
              title: 'Gestion des cookies',
              url: 'javascript:Didomi.preferences.show()'
            }
          ]
        }
      }
    ]
  };

  if (isMobile)
    return (
      <StyledLinkMobileContainer>
        <StyledLogoMobileContainer>
          <img src={logo} alt="logo exaprint"></img>
        </StyledLogoMobileContainer>
        {blocks?.items?.map(column => {
          return (
            <StyledCategoryListWrapper>
              <ItemsMobileLinkList linksCollection={column.linksCollection} />
            </StyledCategoryListWrapper>
          );
        })}
      </StyledLinkMobileContainer>
    );

  return (
    <StyledLinkContainer>
      <StyledLogoContainer>
        <img src={logo} alt="logo exaprint"></img>
      </StyledLogoContainer>
      {blocks?.items?.map(column => {
        return (
          <StyledCategoryListWrapper>
            <ItemsLinkList linksCollection={column.linksCollection} />
          </StyledCategoryListWrapper>
        );
      })}
    </StyledLinkContainer>
  );
};

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 60px;
`;

const StyledLogoMobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    max-width: 65%;
  }
`;

const StyledLinkContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 140px;
`;

const StyledLinkMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledCategoryListWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > span {
    font-family: 'Styrene Medium', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    align-items: end;
    display: flex;
    height: 45px;
  }

  > img {
    height: 45px;
  }

  > ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 200px;
    width: 100%;
  }

  & a {
    color: #ebebeb;
    font-family: 'StyreneA', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    transition: color 0.2s ease-in;

    &:hover {
      color: #fff;
    }
  }
`;
