import React, { Component } from 'react';
import styled from 'styled-components';
import Cookies from 'js-cookie';
import { withTranslation, WithTranslation } from 'react-i18next';

const BrowserBannerContainer = styled.div`
  background-color: #da1e5c;
  border-bottom: 1px solid #981540;
  color: #fff;
  padding: 0.5rem;
  a {
    color: white;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
    color: white;
  }
`;
const BrowserBannerContent = styled.div`
  margin: auto;
`;

const BrowserBannerIgnore = styled.button`
  appearance: none;
  user-select: none;
  border: 1px solid rgba(250, 250, 250, 0.2);
  background-color: white;
  border-radius: 1px;
  color: #da1e5c;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 12px;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  margin-right: 1rem;

  :hover {
    background-color: #e5e5e5;
    color: #343434;
  }
`;

const BrowserBannerAction = styled.div`
  margin: 8px auto 0;
`;

interface State {
  displayBanner: boolean;
}

class BrowserBannerComponent extends Component<WithTranslation, State> {
  constructor(props: WithTranslation) {
    super(props);
    const cookieValue = Cookies.get('deprecated-browser-ignore');

    this.state = {
      displayBanner: cookieValue !== 'ignore'
    };
  }

  private onClickIgnore = () => {
    Cookies.set('deprecated-browser-ignore', 'ignore');
    this.setState({ displayBanner: false });
  };

  public render() {
    return (
      <>
        {this.state.displayBanner && (
          <BrowserBannerContainer>
            <div className="columns">
              <div className="column is-7">
                <BrowserBannerContent dangerouslySetInnerHTML={{ __html: this.props.t('gatsby.browserSupport.bannerLabel') }} />
              </div>
              <div className="column has-text-right">
                <BrowserBannerAction>
                  <BrowserBannerIgnore onClick={this.onClickIgnore}>{this.props.t('gatsby.browserSupport.bannerIgnore')}</BrowserBannerIgnore>
                </BrowserBannerAction>
              </div>
            </div>
          </BrowserBannerContainer>
        )}
        {this.props.children}
      </>
    );
  }
}

export const BrowserBanner = withTranslation('translations')(BrowserBannerComponent);
